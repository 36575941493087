import React from "react";
import styles from "./styles.css";
import Card from "./Card/Card";

function App() {
   return (
      <>
         <div className="main-container">
            <h1 className={styles.text}>Daniel och Elinas bröllop</h1>
            <h4 className="subheader">31 maj 2025</h4>
            <Card
               subject="Välkommen på vårt bröllop!"
               text={
                  <>
                     Vigsel kl. 16 i Rättviks kyrka, entré via sidoingången mot
                     parkeringen. Därefter middag och fest hela natten i
                     Vikarbyns bystuga.
                  </>
               }
            />
            <Card
               subject="OSA"
               text={
                  <>
                     Meddela om du kommer eller inte kommer senast 31 mars.
                     Berätta gärna om du kommer på både vigseln och middagen. Du
                     får självklart ta med din en +1. Meddela oss i så fall.
                     <span className="line"></span>
                     <div>
                        <p>
                           Hör av dig till någon av oss via SMS eller Messenger:
                        </p>
                        <p>Daniel: 073-5104224</p>
                        <p>Elina: 073-6656753</p>
                     </div>
                  </>
               }
            />
            <Card subject="Barnfritt" text={<>Lämna ungarna hemma.</>} />
            <Card
               subject="Inga presenter tack"
               text={
                  <>
                     Vi behöver inga grejer. Vill du ändå ge en gåva får du
                     gärna bidra till vår bröllopsresa.
                  </>
               }
            />
            <Card
               subject="Klädkod"
               text={
                  <>
                     Mörk kostym? Men det viktigaste är att du känner dig fin
                     och bekväm.
                  </>
               }
            />
            <Card
               subject="Aktiviteter under kvällen"
               text={
                  <>
                     Vill du hålla ett tal, sjunga en sång eller har någon annan
                     kul idé till festen? Hör av dig till kulgruppen via SMS
                     eller Messenger.
                     <div>
                        <p>Namn: nummer</p>
                        <p>Namn: nummer</p>
                        <p>Namn: nummer</p>
                     </div>
                  </>
               }
            />
            <Card
               subject="Boende"
               text={
                  <>
                     Här kommer några tips på boenden i Rättvik: Backlunds
                     boende, Turisthotellet och Lerdalshöjden, Fyrklöverns
                     stugby
                  </>
               }
            />
            <Card
               subject="Transport"
               text={
                  <>
                     Vi planerar att fixa skjuts från kyrkan till bystugan efter
                     vigseln samt skjuts från bystugan till hotellen i Rättvik
                     efter festen.
                  </>
               }
               last={true}
            />
         </div>
      </>
   );
}

export default App;
