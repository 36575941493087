import React from "react";
import styles from "../styles.css";

const Card = ({ subject, text, image, last }) => {
  return (
    <div className="card">
      <h2>{subject}</h2>
      <div className="card-text-container">
        <p>{text}</p>
      </div>
      {!last && <div className="line"></div>}
    </div>
  );
};

export default Card;
